//const textFit = require('textFit');

import textFit from 'textFit';
//import Webamp from 'webamp';

//import funkamentekkthnxbye2021mix from "../media/mixes/Funkamente-KKTHNXBYE2021MIX.mp3";
//const funkamentekkthnxbye2021mix = new URL("../media/mixes/Funkamente-KKTHNXBYE2021MIX.mp3", import.meta.url);

const refitText = () => {
    textFit(document.getElementsByClassName('fitted-text'), {
        multiLine: true,
        alignHoriz: false,
        alignVert: true,
        alignVertWithFlexbox: true,
        maxFontSize: 17
    })

    textFit(document.getElementsByClassName('fitted-title'), {
        multiLine: false,
        alignHoriz: true,
        alignVert: true,
        alignVertWithFlexbox: true,
        maxFontSize: 200
    })
}

const toggle = (selector, target) => {
    const element = document.querySelector(selector);

    element.style.display = (element.style.display == 'none' ? 'block' : 'none');

    document.querySelector(target).style.display = 'none';
}

const initWebamp = () => {
    if (Webamp.browserIsSupported()) {
        const webamp = new Webamp({
            // Optional.
            initialTracks: [{
                metaData: {
                    artist: "Funkamente",
                    title: "KKTHNXBYE 2021 MIX",
                },
                // NOTE: Your audio file must be served from the same domain as your HTML
                // file, or served with permissive CORS HTTP headers:
                // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS
                // Can be downloaded from: https://github.com/captbaritone/webamp/raw/master/mp3/llama-2.91.mp3
                url: "https://s3.eu-west-1.amazonaws.com/assets.funkamente.com/mixes/Funkamente-KKTHNXBYE2021MIX.mp3"
            }]
        });
        // Render after the skin has loaded.
        webamp.renderWhenReady(document.getElementById('winamp-container'));
    }
}

window.toggleBio = () => {
    toggle('.bio', 'a#bio-toggle');
    return false;
}

window.toggleContacts = () => {
    toggle('.contacts', 'a#contacts-toggle');
    return false;
}

window.textFit = textFit;
window.addEventListener("DOMContentLoaded", refitText);
window.addEventListener('resize', refitText);